<template>
    <el-dialog :title="`文件${title}`" width="40%" :close-on-click-modal="false" :visible.sync="visible">
        <div class="f_w m_b2" v-if="method == 'move'">您将移动文件：</div>
        <div class="f_w m_b2" v-if="method == 'edit'">您将正在修改以下文件的著录项信息：</div>
        <div class="m_l1 m_b2"><span v-for="(item, index) in list.slice(0,4)" :key="index"><span v-if="sourceType == 'reorganize'">“{{item.fileName}}”</span><span v-if="sourceType == 'cultural'">“{{item.culturalName}}”</span>{{index < 3 && index > 1 ? '、':''}}</span><span v-if="list.length > 5">等</span>{{list.length}}个文件</div>
        <el-form size="small" :model="modelType" :ref="formType" label-width="120px" v-loading="loading">
            <el-form-item v-if="method == 'move'" label="展览：" prop="quanzongNo" :rules="[{ required: true, message: '请选择展览', trigger: 'change' }]">
                <el-select v-model="moveForm.quanzongNo" style="width:100%" @change="manaList" placeholder="请选择展览">
                    <el-option
                            v-for="item in exhibitionList"
                            :key="item.id"
                            :label="item.exhibitionName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="method == 'move'" label="目录：" prop="uploadTarget" :rules="[{ required: true, message: '请选择目录', trigger: 'change' }]">
                <el-select filterable v-model="moveForm.uploadTarget" style="width:100%" placeholder="请选择目录">
                    <el-option
                            v-for="item in catalogueList"
                            :key="item.id"
                            :label="item.directoryName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="method == 'edit'" label="著录项：" prop="catalog" :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
                <el-select v-model="editForm.catalog" style="width:100%" placeholder="请选择">
                    <el-option
                            v-for="item in catalogList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="method == 'edit'" label="值：" prop="val" :rules="[{ required: true, message: '请输入', trigger: 'change' }]">
                <el-input v-model="editForm.val"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                title: '',
                method: '',
                sourceType: '',
                visible: false,
                loading: false,
                list: [],
                catalogueList: [], //目录
                exhibitionList: [], //展览
                formType: '',
                modelType: {},
                moveForm: {
                    quanzongNo:"",
                    uploadTarget:"",
                },
                editForm: {
                    catalog:"",
                    val:"",
                },
                catalogList: [
                    {
                        id: '1',
                        name: '年度',
                    },
                    {
                        id: '2',
                        name: '密集',
                    },
                    {
                        id: '3',
                        name: '保管期限',
                    }
                ],
                log:{
                    operModular:"6",//模块
                    operType:"5",//类型：移动
                    operTerm:"",//内容
                    operSystem:"1",//结果
                },
            }
        },
        methods: {
            init(method, row, type) { // reorganize整编、cultural文物
                this.method = method
                this.sourceType = type
                this.list = row
                if (method === 'move') {
                    this.title = '移动'
                    this.formType = 'moveForm'
                    this.modelType = this.moveForm
                } else if (method === 'edit') {
                    this.title = '修改'
                    this.formType = 'editForm'
                    this.modelType = this.editForm
                }
                this.visible = true
                this.exhibitionInAll(); //所有展览
                this.$nextTick(() => {
                    this.$refs[this.formType].resetFields()
                })
            },
            exhibitionInAll(){
                this.$axios(this.api.zlzs.exhibitionInall, {}, 'get').then(data => {
                    if (data.status) {
                        this.exhibitionList = data.data;
                    }
                })
            },
            manaList(){
                this.moveForm.uploadTarget=""
                this.$axios(this.api.zlzs.selectSysDirectory, {
                    "exhibitionId":this.moveForm.quanzongNo
                }, 'get').then(data => {
                    if (data.status) {
                        this.catalogueList = data.data;
                        // if(this.catalogueList.length>0){
                            // this.moveForm.uploadTarget=this.catalogueList[0]
                        // }
                    }
                })
            },
            //新增日志
            logSave(){
                let that = this;
                let batchList = []
                this.list.forEach(function (v) {
                    let log = JSON.parse(JSON.stringify(that.log))
                    log.operTerm = v.fileName
                    batchList.push(log)
                })
                this.$axios(this.api.zlzs.batchInsertLogin,batchList,'post').then(data => {
                })
            },
            // 表单提交
            doSubmit() {
                this.$refs[this.formType].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if (this.method === 'move') {
                            // 保存移动
                            let ids = this.list.map(item => {
                                return item.id
                            }).join(',')
                            this.$axios(this.api.zlzs.fileCollectBatchUpdateById, {
                                'ids': ids,
                                "uploadTarget":this.moveForm.uploadTarget,
                                "quanzongNo":this.moveForm.quanzongNo,
                                "assTypeValue":1,
                                "type":1
                            }, 'put').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success("移动成功")
                                    this.log.operSystem = '0'
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }else{
                                    this.$message.error(data.msg)
                                }
                                // this.logSave();
                            })
                        } else if (this.method === 'edit') {
                            // 保存修改
                        }
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>
